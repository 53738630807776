<div aria-orientation="vertical" class="nav flex-column nav-pills" id="v-pills-tab" role="tablist">
  <a aria-controls="v-pills-info"
     aria-selected="true"
     class="nav-link"
     data-toggle="pill"
     id="v-pills-profile-tab"
     role="tab"
     routerLink="/about/info"
     routerLinkActive="active">About</a>
  <a [class]="'nav-link' + activeClass('bio')"
     aria-controls="v-pills-bio"
     aria-selected="true"
     data-toggle="pill"
     href="#v-pills-bio"
     id="v-pills-profile-tab"
     role="tab"
     routerLink="/about/bio"
     routerLinkActive="active">Bio</a>
<!--
  <a [class]="'nav-link' + activeClass('contact')"
     aria-controls="v-pills-contact"
     aria-selected="false"
     data-toggle="pill"
     href="#v-pills-contact"
     id="v-pills-messages-tab"
     role="tab"
     routerLink="/about/contact"
     routerLinkActive="active">Contact</a>
-->
<!--
  <a [class]="'nav-link' + activeClass('news')"
     aria-controls="v-pills-news"
     aria-selected="false"
     data-toggle="pill"
     href="#v-pills-news"
     id="v-pills-settings-tab"
     role="tab"
     routerLink="/about/news"
     routerLinkActive="active">News</a>
-->
</div>
