import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-page-not-fount',
  templateUrl: './page-not-fount.component.html',
  styleUrls: ['./page-not-fount.component.css']
})
export class PageNotFountComponent implements OnInit {

  constructor() {
  }

  ngOnInit(): void {
  }

}
