import {AfterViewChecked, Component, Input, OnInit, ViewChild} from '@angular/core';
import {Artwork} from 'src/app/model/artwork';
import {NgbCarousel, NgbSlideEvent} from '@ng-bootstrap/ng-bootstrap';
import {ArtworksService} from 'src/app/service/artworks.service';
import {ActivatedRoute} from '@angular/router';
import {GetArtworksResponse} from '../../model/get-artworks-response';
import {GoogleAnalyticsService} from '../../service/google-analytics.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-artwork-detail',
  templateUrl: './artwork-detail.component.html',
  styleUrls: ['./artwork-detail.component.css']
})
export class ArtworkDetailComponent implements OnInit, AfterViewChecked {
  artworkIdInternal: string;
  artworks: Artwork[];
  year: number;
  loadedPages: number;
  totalPages: number;
  pageSize = 3;
  loading = false;
  pageImagesloaded = 0;

  @ViewChild(NgbCarousel) carousel: NgbCarousel;
  innerHeight: number;

  constructor(private artworksService: ArtworksService,
              private gaService: GoogleAnalyticsService,
              private route: ActivatedRoute,
              private deviceService: DeviceDetectorService) {
  }

  get artworkId() {
    return this.artworkIdInternal;
  }

  @Input() set artworkId(value: string) {
    console.log('set artworkId = ' + value);
    this.artworkIdInternal = value;
    this.artworksService.getArtwork(this.artworkIdInternal)
      .subscribe((a: Artwork) => {
        if (environment.cdn.enabled) {
          a.imagePath = a.imagePath.replace(environment.cdn.originUrl, environment.cdn.cdnUrl);
        }
        this.artworks = [a];
        this.artworksService.getArtworksForYear(a.year, 1, 20)
          .subscribe((res: GetArtworksResponse) => {
            res.artworks.forEach((artwork: Artwork) => {
              if (environment.cdn.enabled) {
                artwork.imagePath = artwork.imagePath.replace(environment.cdn.originUrl, environment.cdn.cdnUrl);
              }
            });
            this.artworks = res.artworks;
          });
      });
  }

  ngOnInit(): void {
    this.innerHeight = window.innerHeight;
    this.artworkIdInternal = this.route.snapshot.params.id;
    this.year = this.route.snapshot.params.year;
    this.artworks = [];
    this.loadedPages = 0;
    this.totalPages = 0;
    this.pageImagesloaded = 0;
    this.pageSize = 2;
    this.getArtworks(this.year, 1);
  }

  private getArtworks(year: number, page: number){
    this.loading = true;
    this.loadedPages = page;
    this.pageImagesloaded = 0;
    this.artworksService.getArtworksForYear(year, page, this.pageSize)
      .subscribe((res: GetArtworksResponse) => {
        this.totalPages = res.totalCount / this.pageSize;
        res.artworks.forEach( artwork => {
          if (environment.cdn.enabled) {
            artwork.imagePath = artwork.imagePath.replace(environment.cdn.originUrl, environment.cdn.cdnUrl);
          }
          this.artworks.push( artwork );
        } );
        this.loading = false;
        this.afterArtworksLoaded();
      });
  }

  afterArtworksLoaded() {
    this.carousel.activeId = 'artwork_' + this.artworkIdInternal;
  }

  ngAfterViewChecked() {
    this.carousel.pause();
  }

  onImageLoaded(caption: HTMLDivElement){
    this.pageImagesloaded = this.pageImagesloaded + 1;
    if ( !this.loading && this.pageImagesloaded >= this.pageSize && this.totalPages > this.loadedPages ){
      this.getArtworks(this.year, this.loadedPages + 1 );
    }
    if ( this.deviceService.isMobile() ){
      caption.hidden = false;
      setTimeout(() => caption.hidden = true, 5000);
    }
  }

  getImageWidth(artworkImg: HTMLImageElement) {
    return artworkImg.width;
  }

  getCaptionLeftForImg(artworkImg: HTMLImageElement) {
    return (window.innerWidth - artworkImg.width) / 2;
  }

  onMouseenter(caption: HTMLDivElement) {
    caption.hidden = false;
    this.gaService.eventEmitter('view_item', 'artwork', 'viewCaption');
  }

  onMouseleave(caption: HTMLDivElement) {
    caption.hidden = true;
  }

  onClick(caption: HTMLDivElement) {
    caption.hidden = !caption.hidden;
  }

  onSlideTransition(event: NgbSlideEvent) {
    console.log('onSlideTransition ' + event.prev + '>' + event.current);
    console.log('device: ' + this.deviceService.isMobile());
    if ( this.deviceService.isMobile() ){
      const caption = document.getElementById('caption_' + event.current);
      caption.hidden = false;
      setTimeout(() => caption.hidden = true, 5000);
    }
  }
}
