import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Artwork} from 'src/app/model/artwork';
import {ArtworksService} from 'src/app/service/artworks.service';
import {ActivatedRoute} from '@angular/router';
import {GetArtworksResponse} from '../../model/get-artworks-response';
import {GoogleAnalyticsService} from '../../service/google-analytics.service';
import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-artwork-list',
  templateUrl: './artwork-list.component.html'
})
export class ArtworkListComponent {
  artworks: Artwork[];
  @Output() artworkSelection: EventEmitter<string> = new EventEmitter();
  loadedPages: number;
  totalPages: number;
  pageSize = 3;
  loading = false;
  pageImagesloaded = 0;

  constructor(private artworksService: ArtworksService,
              private route: ActivatedRoute) {
  }

  _year = 2020;

  get year(): number {
    return this._year;
  }

  @Input() set year(value: number) {
    this._year = value;
    this.artworks = [];
    this.loadedPages = 0;
    this.totalPages = 0;
    this.pageImagesloaded = 0;
    switch (new Date().getFullYear() - this._year) {
      case 0:
        this.pageSize = 2;
        break;
      case 1:
      case 2:
        this.pageSize = 4;
        break;
      default:
        this.pageSize = 6;
    }
    this.getArtworks(this._year, 1);
  }

  private getArtworks(year: number, page: number){
    this.loading = true;
    this.loadedPages = page;
    this.pageImagesloaded = 0;
    this.artworksService.getArtworksForYear(year, page, this.pageSize)
      .subscribe((res: GetArtworksResponse) => {
        this.totalPages = res.totalCount / this.pageSize;
        res.artworks.forEach( artwork => {
          if (environment.cdn.enabled) {
            artwork.imagePath = artwork.imagePath.replace(environment.cdn.originUrl, environment.cdn.cdnUrl);
          }
          return this.artworks.push( artwork );
        } );
        this.loading = false;
      });
  }

  ngOnInit() {
    this.year = this.route.snapshot.params.year;
  }

  onImageLoaded(){
    this.pageImagesloaded = this.pageImagesloaded + 1;
    if ( !this.loading && this.pageImagesloaded >= this.pageSize && this.totalPages > this.loadedPages ){
      this.getArtworks(this._year, this.loadedPages + 1 );
    }
  }

  getColWidth(year: number) {
    switch (new Date().getFullYear() - year) {
      case 0:
        return 'md-12';
      case 1:
      case 2:
        return 'md-6';
      default:
        return 'md-4';
    }
  }

  onArtworkSelection(artworkId: string) {
    this.artworkSelection.emit(artworkId);
  }
}

