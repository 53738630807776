import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Artwork} from 'src/app/model/artwork';
import {KeyValue} from '@angular/common';
import {ArtworksService} from 'src/app/service/artworks.service';
import {YearCount} from '../../model/count-artworks-per-year';
import {GetArtworksResponse} from '../../model/get-artworks-response';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-artwork-overview',
  templateUrl: './artwork-overview.component.html',
  styleUrls: ['./artwork-overview.component.css']
})
export class ArtworkOverviewComponent implements OnInit {
  yearsCount: Map<number, number> = new Map<number, number>();
  years: Map<number, Artwork> = new Map<number, Artwork>();

  @Output() yearSelection: EventEmitter<string> = new EventEmitter();

  constructor(private artworksService: ArtworksService) {
  }

  ngOnInit(): void {
    this.artworksService.getYears().subscribe((yearsCount: YearCount[]) => {
      for (const yearCount of yearsCount) {
        this.yearsCount.set(yearCount.year, yearCount.count);
        if (yearCount.count > 0) {
          this.artworksService.getArtworksForYear(yearCount.year, 1, 1).subscribe((res: GetArtworksResponse) => {
            const artwork = res.artworks[0];
            if (environment.cdn.enabled) {
              artwork.imagePath = artwork.imagePath.replace(environment.cdn.originUrl, environment.cdn.cdnUrl);
            }
            this.years.set(yearCount.year, artwork);
          });
        }
      }
    });
  }

  // Order by descending property key
  keyDescOrder = (a: KeyValue<number, Artwork>, b: KeyValue<number, Artwork>): number => {
    return a.key > b.key ? -1 : (b.key > a.key ? 1 : 0);
  };

  getColWidth(index: number) {
    switch (index) {
      case 0:
        return 'md-12';
      case 1:
      case 2:
        return 'md-6';
      default:
        return 'md-4';
    }
  }

  onYearSelection(yearSelection: string) {
    this.yearSelection.emit(yearSelection);
  }
}
