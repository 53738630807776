import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {ArtistComponent} from './artist/artist.component';
import {ArtworkOverviewComponent} from './artworks/artwork-overview/artwork-overview.component';
import {ArtworkListComponent} from './artworks/artwork-list/artwork-list.component';
import {ArtworkDetailComponent} from './artworks/artwork-detail/artwork-detail.component';
import {AboutComponent} from './artist/about/about.component';
import {BioComponent} from './artist/bio/bio.component';
import {ContactsComponent} from './artist/contacts/contacts.component';
import {NewsComponent} from './artist/news/news.component';
import {PageNotFountComponent} from './shared/page-not-fount/page-not-fount.component';


const routes: Routes = [
  {path: '', redirectTo: '/artworks', pathMatch: 'full'},
  {path: 'artworks', component: ArtworkOverviewComponent},
  {path: 'artworks/:year', component: ArtworkListComponent},
  {path: 'artworks/:year/:id', component: ArtworkDetailComponent},
  {
    path: 'about', component: ArtistComponent, children: [
      {path: 'info', component: AboutComponent},
      {path: 'bio', component: BioComponent},
      {path: 'contact', component: ContactsComponent},
      {path: 'news', component: NewsComponent}
    ]
  },
  {path: 'not-found', component: PageNotFountComponent},
  {path: '**', redirectTo: '/not-found'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
