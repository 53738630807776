import {Injectable} from '@angular/core';
import {Artwork} from '../model/artwork';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {CountArtworksPerYear, YearCount} from '../model/count-artworks-per-year';
import {environment} from '../../environments/environment';
import {GetArtworksResponse} from '../model/get-artworks-response';

@Injectable({
  providedIn: 'root'
})
export class ArtworksService {

  constructor(
    private http: HttpClient
  ) {
  }

  getYears(): Observable<YearCount[]> {
    return this.http.get<CountArtworksPerYear>(environment.apiUrl + '/years')
      .pipe(
        catchError(this.handleError),
        map(value => value.counts)
      );
  }

  getArtworksForYear(year: number, page: number = 1, size: number = 1): Observable<GetArtworksResponse> {
    return this.http.get<GetArtworksResponse>(
      environment.apiUrl + '/artworks',
      {
        params: {year: '' + year, page: '' + page, size: '' + size}
      });
  }

  getArtwork(id: string): Observable<Artwork> {
    return this.http.get<Artwork>(environment.apiUrl + '/artworks/' + id);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }
}
