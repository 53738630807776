import {Injectable} from '@angular/core';
import {Artist, Bio} from '../model/artist';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {GetArtistsResponse} from '../model/get-artists-response';

@Injectable({
  providedIn: 'root'
})
export class ArtistService {

  artistId = 0;
  artist: Artist = null;

  constructor(private http: HttpClient) {
  }

  getArtists(): Observable<GetArtistsResponse> {
    return this.http.get<GetArtistsResponse>(environment.apiUrl + '/artists', {params: {page: '1', size: '1'}});
  }

  getArtist(id: number): Observable<Artist> {
    return this.http.get<Artist>(environment.apiUrl + '/artists/' + id);
  }

  private getFirstArtist(): Observable<Artist> {

    const artist = new Observable<Artist>((observer) => {
      if (this.artistId === 0) {
        this.getArtists().subscribe(gar => {
          if (gar != null && gar.artists != null && gar.artists.length > 0) {
            this.artistId = gar.artists[0].id;
            this.getArtist(this.artistId).subscribe(a => observer.next(a));
          } else {
            observer.error('artist not found');
          }
        });
      } else {
        this.getArtist(this.artistId).subscribe(a => observer.next(a));
      }
    });
    return artist;
  }

  getAbout(): Observable<Artist> {
    const artistObservable = new Observable<Artist>((observer) => {
      if (this.artist == null) {
        this.getFirstArtist().subscribe(art => {
          this.artist = art;
          observer.next(this.artist);
        });
      } else {
        observer.next(this.artist);
      }
    });

    return artistObservable;
  }

  getBio(): Observable<Bio[]> {
    const biography = new Observable<Bio[]>((observer) => {
      if (this.artist == null) {
        this.getFirstArtist().subscribe(artist => {
          this.artist = artist;
          observer.next(this.artist.biography);
        });
      } else {
        observer.next(this.artist.biography);
      }
    });
    return biography;
  }
}
