<h4>Send me a Message</h4>
<form>
  <div class="form-group">
    <label for="exampleInputEmail1">Name</label>
    <input class="form-control" id="inputName" placeholder="Enter name" type="text">
  </div>
  <div class="form-group">
    <label for="inputEmail">Email address</label>
    <input aria-describedby="emailHelp" class="form-control" id="inputEmail" placeholder="Enter email" type="email">
    <small class="form-text text-muted" id="emailHelp">We'll never share your email with anyone else.</small>
  </div>
  <div class="form-group">
    <label for="inputSubject">Subject</label>
    <input class="form-control" id="inputSubject" placeholder="Subject" type="text">
  </div>
  <div class="form-group">
    <label for="inputMessage">Message</label>
    <textarea class="form-control" id="inputMessage" placeholder="" rows="10"></textarea>
  </div>
  <button class="btn btn-primary" type="submit">Submit</button>
</form>
