import {Component} from '@angular/core';
import {Router, NavigationEnd} from '@angular/router';
import {environment} from '../environments/environment';

declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(public router: Router){
    this.router.events.subscribe(event => {
        if(event instanceof NavigationEnd){
          gtag('config', environment.gaTrackingID,
            {
              'page_path': event.urlAfterRedirects
            }
          );
        }
      }
    )}

  selection: string = 'overview';

  onHeaderSelection(selection: string) {
    this.selection = selection;
  }
}
