<div class="container">
  <div class="row">
    <div class="col text-center">
      <h4>ARTWORKS</h4>
      <div class="row">
        <div *ngFor="let item of years | keyvalue : keyDescOrder; index as i"
             class="col-{{getColWidth(i)}} text-center">
          <h5 class="m-4"><a [routerLink]="['/artworks',item.key]">{{ item.key }}</a></h5>
          <a [routerLink]="['/artworks',item.key]">
            <img [src]="item.value.imagePath" alt="{{ item.value.name }}" class="img-fluid" style="max-height: 600px">
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
