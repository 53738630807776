import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-artist-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.css']
})
export class NavComponent implements OnInit {
  @Input() active: string;
  @Output() selection: EventEmitter<string> = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
  }

  activeClass(name: string) {
    return this.active === name ? ' active' : '';
  }

  onSelect(selection: string) {
    this.active = selection;
    this.selection.emit(selection);
  }
}
