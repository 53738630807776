<div *ngIf="exhibitions().length > 0" class="row mb-3">
  <div class="col">
    <h4>Exhibitions</h4>
  </div>
</div>
<div *ngFor="let b of exhibitions()" class="row mb-2">
  <div class="col">
    <p><strong>{{b.description}}</strong> <i *ngIf="b.location"> {{b.location}}</i> - {{b.date | date:'MMMM, y'}}</p>
  </div>
</div>
<div *ngIf="awards().length > 0" class="row mb-3 mt-2">
  <div class="col">
    <h4>Awards</h4>
  </div>
</div>
<div *ngFor="let b of awards()" class="row mb-2">
  <div class="col">
    <p><strong>{{b.description}}</strong> <i *ngIf="b.location"> {{b.location}}</i> - {{b.date | date:'MMMM, y'}}</p>
  </div>
</div>
<div *ngIf="publications().length > 0" class="row mb-3 mt-2">
  <div class="col">
    <h4>Publications</h4>
  </div>
</div>
<div *ngFor="let b of publications()" class="row mb-2">
  <div class="col">
    <p><strong>{{b.description}}</strong> <i *ngIf="b.location"> {{b.location}}</i> - {{b.date | date:'MMMM, y'}}</p>
  </div>
</div>
<div *ngIf="others().length > 0" class="row mb-3 mt-2">
  <div class="col">
    <h4>Other</h4>
  </div>
</div>
<div *ngFor="let b of others()" class="row mb-2">
  <div class="col">
    <p><strong>{{b.description}}</strong> <i *ngIf="b.location"> {{b.location}}</i> - {{b.date | date:'MMMM, y'}}</p>
  </div>
</div>
