<div class="container">
  <div class="row">
    <div class="col text-center">
      <h4 class="m-3">{{ year }}</h4>
      <div class="row">
        <div *ngFor="let artwork of artworks; index as i" class="col-{{getColWidth(year)}} text-center">
          <a [routerLink]="[artwork.id]">
            <img [src]="artwork.imagePath" (load)="onImageLoaded()" alt="{{ artwork.name }}" class="img-fluid" style="max-height: 600px">
          </a>
          <p class="mt-2 mb-4">{{ artwork.name }}</p>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="totalPages > loadedPages" class="row">
    <div class="col text-center m-4">
      <mat-spinner [diameter]="60" style="margin:0 auto;"></mat-spinner>
    </div>
  </div>
</div>
