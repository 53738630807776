<div class="container mt-5">
  <div class="row">
    <div class="d-none d-md-block col-md-3 pr-5">
      <app-artist-nav (selection)="onNavSelection($event)" [active]="selection"></app-artist-nav>
    </div>
    <div class="col-12 col-md-9">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>
