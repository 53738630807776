<nav class="navbar navbar-expand-md navbar-light bg-light d-flex flew-row justify-content-between">
  <a class="navbar-brand" href="#" routerLink="/">JudithShaylor.com</a>
  <button aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" class="navbar-toggler"
          data-target="#navbarSupportedContent" data-toggle="collapse" type="button">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    <ul class="navbar-nav ml-auto">
      <li [routerLinkActiveOptions]="{exact: true}" class="nav-item" routerLinkActive="active">
        <a class="nav-link" routerLink="/">Home</a>
      </li>
      <li class="nav-item dropdown" routerLinkActive="active">
        <a aria-expanded="false" aria-haspopup="true" class="nav-link dropdown-toggle" data-toggle="dropdown" href="#"
           id="navbarDropdown" role="button">
          About
        </a>
        <div aria-labelledby="navbarDropdown" class="dropdown-menu">
          <a class="dropdown-item" routerLink="/about/info">Info</a>
          <a class="dropdown-item" routerLink="/about/bio">Bio</a>
          <!--
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" routerLink="/about/contact">Contact</a>
          -->
        </div>
      </li>
<!--
      <li class="nav-item" routerLinkActive="active">
        <a class="nav-link" href="#" routerLink="/about/news">News</a>
      </li>
-->
    </ul>
  </div>
</nav>
