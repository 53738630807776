import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {ArtworkDetailComponent} from './artworks/artwork-detail/artwork-detail.component';
import {ArtworkListComponent} from './artworks/artwork-list/artwork-list.component';
import {ArtworkOverviewComponent} from './artworks/artwork-overview/artwork-overview.component';
import {AboutComponent} from './artist/about/about.component';
import {NewsComponent} from './artist/news/news.component';
import {BioComponent} from './artist/bio/bio.component';
import {ContactsComponent} from './artist/contacts/contacts.component';
import {HeaderComponent} from './shared/header/header.component';
import {FooterComponent} from './shared/footer/footer.component';
import {ArtworksComponent} from './artworks/artworks.component';
import {ArtistComponent} from './artist/artist.component';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NavComponent} from './artist/nav/nav.component';
import {ArtworksService} from './service/artworks.service';
import {ArtistService} from './service/artist.service';
import {PageNotFountComponent} from './shared/page-not-fount/page-not-fount.component';
import {HttpClientModule} from '@angular/common/http';
import {DatePipe} from '@angular/common';
//import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    AppComponent,
    ArtworkDetailComponent,
    ArtworkListComponent,
    ArtworkOverviewComponent,
    AboutComponent,
    NewsComponent,
    BioComponent,
    ContactsComponent,
    HeaderComponent,
    FooterComponent,
    ArtworksComponent,
    ArtistComponent,
    NavComponent,
    PageNotFountComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FontAwesomeModule,
    NgbModule,
    HttpClientModule,
    MatProgressSpinnerModule,
  //  BrowserAnimationsModule
  ],
  providers: [
    ArtworksService,
    ArtistService,
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
