<div *ngIf="artist" class="jumbotron jumbotron-fluid">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-4 text-center">
        <img [src]="artist.imagePath" class="img-thumbnail">
      </div>
      <div class="col-md-8 col-sm-12">
        <h1 class="display-4">{{ artist.name }}</h1>
        <p class="lead">{{ artist.lead }}</p>
      </div>
    </div>
  </div>
</div>
<div *ngIf="artist" class="container">
  <div class="row">
    <div class="col">
      <p *ngFor="let p of artist.about.split('/\r?\n/')">{{ p }}</p>
    </div>
  </div>
</div>
