import {Component, Input, OnInit} from '@angular/core';
import {ArtworksService} from '../service/artworks.service';

@Component({
  selector: 'app-artworks',
  templateUrl: './artworks.component.html',
  styleUrls: ['./artworks.component.css'],
})
export class ArtworksComponent implements OnInit {

  @Input() selection = 'overview';
  @Input() yearSelection = '';
  @Input() artworkId = '';

  constructor(private artworksService: ArtworksService) {
  }

  ngOnInit(): void {
  }

  onYearSelection(yearSelection: string) {
    this.selection = 'year';
    this.yearSelection = yearSelection;
  }

  onArtworkSelection(artworkId: string) {
    this.selection = 'artwork';
    this.artworkId = artworkId;
  }
}
