<ngb-carousel *ngIf="artworks" [showNavigationIndicators]="false" (slide)="onSlideTransition($event)">
  <ng-template *ngFor="let artwork of artworks" [id]="'artwork_' + artwork.id" ngbSlide>
    <div class="picsum-img-wrapper text-center w-100 m-0 p-0" style="background-color: #7d7d7d;">
      <img #artworkImg
           (click)="onClick(caption)"
           (mouseenter)="onMouseenter(caption)"
           (mouseleave)="onMouseleave(caption)"
           [alt]="artwork.name"
           [src]="artwork.imagePath"
           [style]="'max-height: ' + (innerHeight*0.85) + 'px;'"
           class="img-fluid"
           (load)="onImageLoaded(caption)">
      <div #caption
           [style]="'width: ' + getImageWidth(artworkImg) + 'px; left: ' + getCaptionLeftForImg(artworkImg) + 'px;'"
           class="carousel-caption"
           hidden="true"
           [id]="'caption_artwork_' + artwork.id">
        <h6>{{artwork.name}}</h6>
        <p>{{artwork.dimensions + ' ' + artwork.technique}}</p>
      </div>
    </div>
  </ng-template>
</ngb-carousel>
