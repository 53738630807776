import {Component, OnInit} from '@angular/core';
import {faFacebook, faInstagram, faTwitter} from '@fortawesome/free-brands-svg-icons';
import {faEnvelope} from '@fortawesome/free-regular-svg-icons';
import {ArtistService} from '../../service/artist.service';
import {GoogleAnalyticsService} from '../../service/google-analytics.service';

const email = 'EMAIL';
const facebook = 'FACEBOOK';
const twitter = 'TWITTER';
const instagram = 'INSTAGRAM';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  year = new Date().getFullYear();
  faFacebook = faFacebook;
  faInstagram = faInstagram;
  faTwitter = faTwitter;
  faEmail = faEnvelope;
  facebookId = null;
  twitterId = null;
  instagramId = null;
  email = null;

  constructor(
    private artistService: ArtistService,
    private gaService: GoogleAnalyticsService) {
  }

  ngOnInit(): void {
    this.artistService.getAbout().subscribe(artist => {
      artist.contacts.forEach(contact => {
        switch (contact.type) {
          case facebook:
            this.facebookId = contact.value;
            break;
          case twitter:
            this.twitterId = contact.value;
            break;
          case instagram:
            this.instagramId = contact.value;
            break;
          case email:
            this.email = contact.value;
            break;
        }
      });
    });
  }

  facebookUrl(): string {
    if ( this.facebookId ){
      return `https://www.facebook.com/${this.facebookId}/`;
    } else {
      return null;
    }
  }

  instagramUrl(): string {
    if ( this.instagramId ){
      return `https://www.instagram.com/${this.instagramId}/`;
    } else {
      return null;
    }
  }

  twitterUrl(): string {
    if ( this.twitterId ){
      return `https://twitter.com/${this.twitterId}`;
    } else {
      return null;
    }
  }

  onContactClick(type: string) {
    this.gaService.eventEmitter('select_content', 'contacts', 'click', type, 10);
  }
}
