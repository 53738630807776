import {Component, OnInit} from '@angular/core';
import {ArtistService} from 'src/app/service/artist.service';
import {Artist} from 'src/app/model/artist';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  artist: Artist;

  constructor(private artistService: ArtistService) {
  }

  ngOnInit(): void {
    this.artistService.getAbout().subscribe(artist => {
      this.artist = artist;
    });
  }

}
