import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-artist',
  templateUrl: './artist.component.html',
  styleUrls: ['./artist.component.css']
})
export class ArtistComponent implements OnInit {

  @Input() selection: string = 'about';

  constructor() {
  }

  ngOnInit(): void {
  }

  onNavSelection(selection: string) {
    this.selection = selection;
  }
}
