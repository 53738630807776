import {Component, OnInit} from '@angular/core';
import {Bio} from 'src/app/model/artist';
import {ArtistService} from 'src/app/service/artist.service';

@Component({
  selector: 'app-bio',
  templateUrl: './bio.component.html',
  styleUrls: ['./bio.component.css']
})
export class BioComponent implements OnInit {

  bio: Bio[] = [];

  constructor(private artistService: ArtistService) {
  }

  ngOnInit(): void {
    this.artistService.getBio().subscribe(biography => {
      this.bio = biography;
      this.bio.forEach( b => {
        if ( !b.type ) {
          b.type = 'OTHER';
        }
      });
    });
  }

  exhibitions(): Bio[] {
    return this.bioByType('EXHIBITION');
  }

  awards(): Bio[] {
    return this.bioByType('AWARD');
  }

  publications(): Bio[] {
    return this.bioByType('PUBLICATION');
  }

  others(): Bio[] {
    return this.bioByType('OTHER');
  }

  bioByType(type: string){
    return this.bio.filter( b => b.type === type);
  }

}
