<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col">
        <span class="text-muted">&copy; {{year}} JudithShaylor.com</span>
      </div>
      <div class="col">
        <a *ngIf="facebookId" class="mr-2" href="{{facebookUrl()}}" (click)="onContactClick('facebook')">
          <fa-icon [icon]="faFacebook"></fa-icon>
        </a>
        <a *ngIf="instagramId" class="mr-2" href="{{instagramUrl()}}" (click)="onContactClick('instagram')">
          <fa-icon [icon]="faInstagram"></fa-icon>
        </a>
        <a *ngIf="twitterId" class="mr-2" href="{{twitterUrl()}}" (click)="onContactClick('twitter')" >
          <fa-icon [icon]="faTwitter"></fa-icon>
        </a>
        <a *ngIf="email" class="mr-2" href="mailto:{{email}}" (click)="onContactClick('email')">
          <fa-icon [icon]="faEmail"></fa-icon>
        </a>
      </div>
    </div>
  </div>
</footer>

