export const environment = {
  production: false,
  // apiUrl: 'http://192.168.0.24'
  // apiUrl: 'http://jsa-api-test.thewindgod.com',
  apiUrl: 'https://jsa-api-test.thewindgod.com',
  gaTrackingID: 'x',
  cdn: {
    enabled: true,
    originUrl: 'https://judeshayart-test-assets.s3.eu-west-1.amazonaws.com',
    cdnUrl: 'https://assets.test.judithshaylor.com'
  }
};
